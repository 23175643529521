<template>
  <div class="content">
    <div class="box1">
      <div class="left">
        <div class="left-title">资产管理系统</div>
        <div class="left-text">
          对日常管理中的资产信息进行实时监控、记录和自动更新，同时采集人员信息，真正实现“帐、卡、物、人”相符；将原来分散的资产管理信息集中起来，组合成为一个整体的资产管理信息平台，从而避免因人为因素造成的信息失真引起管理效能的下降。
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 16%;
  min-height: calc(100vh - 317px);
  .box1 {
    width: 100%;
    height: 430px;
    display: flex;
    background-color: #fff;
    margin-top: 30px;
    padding: 30px;
    .left {
      width: 50%;
      padding: 5% 10%;
      box-sizing: border-box;
      .left-title {
        font-size: 28px;
        font-weight: 600;
        padding: 30px 0;
        color: #333333;
      }
      .left-text {
        font-size: 16px;
        font-weight: 400;
        color: #6b6b6b;
      }
    }
    .right {
      width: 50%;
      height: 100%;
      background: url("../../../../assets/image/parkImg/蒙版组 147.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>